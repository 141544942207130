<template>
    <v-text-field :dense="dense" type="text" v-model="input" @input="onInput" :option="{currency: 'IDR'}" :disabled="disabled" :label="label" required></v-text-field>
    <!-- <input class="form-control" type="text" v-model="input" @input="onInput" :disabled="disabled" /> -->
</template>

<script>
    export default {
        name: 'CustomInput',
        props: ['value', 'dense', 'disabled', 'allowZero', 'label'],
        data() {
            return {
                input: this.value,
            }
        },
        watch: {
            value: {
                handler(v){
                    this.input = this.$moneyFormat(v, false, this.allowZero);
                },
                deep: true
            }
        },
        mounted(){
            this.input = this.$moneyFormat(this.value, true, this.allowZero)
        },
        methods: {
            onInput() {
                let x = this.input.toString()
                // x = x.replaceAll(".", "")
                // x = x.replaceAll(/[^\d]/g, "", "")
                this.$emit('input', x);
            },
        },
    }
</script>